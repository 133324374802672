import { render, staticRenderFns } from "./local-file-manager.vue?vue&type=template&id=1ddfbf9d&scoped=true&"
import script from "./local-file-manager.vue?vue&type=script&lang=js&"
export * from "./local-file-manager.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ddfbf9d",
  null
  
)

export default component.exports